import React, { useContext, useState } from "react";
import userAvatar from "../../assets/svgs/userAvatar.svg";
import editPen from "../../assets/svgs/editPen.svg";
import profilePhoto from "../../assets/images/profilePhoto.png";
import profileMsisdn from "../../assets/images/profileMsisdn.png";
import { appContext } from "../../context/context";
import CustomButton from "../../reusable/CustomButton";
import NameInput from "../../reusable/NameInput";
import useUpdateUserName from "../../hooks/user/useUpdateUserName";
import formatMsisdn from "../../functions/formatMsisdn";
import { logEvent } from "firebase/analytics";
import analytics from "../../firebase";

const UserProfileSection = ({ isOpen }) => {
  const { showPopup, closePopup } = useContext(appContext);
  const { userName, msisdn } = useContext(appContext);
  const showUserProfileInfoDiv = () => {
    logEvent(analytics, "User_Profile_Clicked", {
      date: new Date().toISOString(),
    });
    showPopup(userProfileInfoDiv);
  };
  const userProfileInfoDiv = <UserProfileInfoSection closePopup={closePopup} />;
  return (
    <div
      className="relative h-[10.5em] mobile:h-[13.5em] flex justify-center  cursor-pointer"
      onClick={showUserProfileInfoDiv}
    >
      <div
        id="profileDiv"
        className="text-center mb-4 absolute w-full h-full top-0 left-0"
      ></div>
      <div className="flex justify-center items-center space-x-[0.9em]">
        <img
          className={`${
            isOpen
              ? "laptop:w-[7.2em] laptop:h-[7.2em]"
              : "laptop:w-[5.2em] laptop:h-[5.2em]"
          } w-[5.2em] h-[5.2em] rounded-full `}
          src={userAvatar}
          alt="User Avatar"
        />
        <div
          className={`whitespace-nowrap text-[#fffB3] transition-all duration-500 ease-in-out ${
            isOpen ? "block" : "hidden"
          }`}
        >
          <h2 className="mt-2 text-[2em] font-[700]">{userName}</h2>
          <p className="laptop:text-[1.7em] tablet:text-[1.5em]">
            {formatMsisdn(msisdn)}
            {/* +92 xxx xxxxxxx */}
          </p>
        </div>
      </div>
    </div>
  );
};

const UserProfileInfoSection = ({ closePopup }) => {
  const updateUserName = useUpdateUserName();
  const { userName, msisdn } = useContext(appContext);
  const [message, setMessage] = useState(null);
  const [usernameState, setUsernameState] = useState(userName);
  const [isEditNameMode, setIsEditNameMode] = useState(false);
  const startEditMode = () => setIsEditNameMode(true);
  const endEditMode = () => setIsEditNameMode(false);
  const handleContinue = () => {
    if (isEditNameMode) {
      if (usernameState.length > 0) {
        updateUserName(usernameState);
        setMessage(null);
        closePopup();
        endEditMode();
      } else {
        setMessage("Provide a Name.");
      }
    } else {
      closePopup();
    }
  };
  const handleCancel = () => {
    endEditMode();
    setUsernameState(userName);
  };
  return (
    <div className="bg-white space-y-16 rounded-lg shadow-lg  transform transition-all laptop:w-[40%]  tablet:w-[60%] w-[90%] laptop:max-w-[60vw] tablet:max-w-[80vw] tablet:min-h-max min-h-[40vh]  mx-10  px-[2em] py-[4em] mobile:px-[5em] mobile:py-[7em] tablet:px-[6em] tablet:py-[8em] laptop:px-[8.7em] laptop:py-[11.9em]">
      <div className="h-full w-full flex flex-col justify-between">
        <div className="space-y-4">
          {isEditNameMode ? (
            <div>
              <div>
                <NameInput
                  classNameMobile={" borderInput !p-8 !bg-transparent "}
                  className={" !border-2  !p-8 !bg-transparent "}
                  name={usernameState}
                  setName={setUsernameState}
                />
              </div>
              {message ? <div className="text-red-700">{message}</div> : <></>}
            </div>
          ) : (
            <div className="flex justify-between items-center border-2 rounded-[14px] p-8">
              <div>
                <div className="flex items-center space-x-6">
                  <img className="object-contain" src={profilePhoto} alt="" />
                  <div>
                    <div className="text-[#8789B0] tablet:text-[1.4em] text-[1.3em]">
                      Full Name
                    </div>
                    <div className="text-[#000640CC] laptop:text-[2em] tablet:text-[1.9em] mobile:text-[1.7em] text-[1.6em] ">
                      {userName}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className=" cursor-pointer outline-none"
                  onClick={startEditMode}
                >
                  <img src={editPen} alt="" />
                </button>
              </div>
            </div>
          )}
          <div className="flex items-center border-2 rounded-[14px] p-8">
            <div>
              <div className="flex items-center space-x-6">
                <img src={profileMsisdn} className="object-contain" alt="" />
                <div>
                  <div className="text-[#8789B0] mobile:text-[1.4em] text-[1.3em]">
                    {/* MSISDN */}
                    Mobile no.
                  </div>
                  <div className="text-[#000640C7] laptop:text-[2em] tablet:text-[1.9em] mobile:text-[1.7em] text-[1.6em] opacity-80">
                    {formatMsisdn(msisdn)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <CustomButton
            text={isEditNameMode ? "Save" : "Continue"}
            onClick={handleContinue}
            className={
              "tablet:!text-[2em] mobile:!text-[1.8em] !text-[1.5em] font-[600] mobile:!py-[1em] !w-full px-[1.5em] !py-[1.2em] mt-[4.7em] uppercase"
            }
            isDisabled={isEditNameMode && userName === usernameState}
          />
          {isEditNameMode ? (
            <div
              className="cursor-pointer select-none mt-4 text-center text-[#000640CC] text-[2em]"
              onClick={handleCancel}
            >
              Cancel
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfileSection;
