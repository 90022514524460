import React, { useContext, useEffect, useState } from "react";
import signInBackground from "../assets/images/signInBackground.png";
import signInBackgroundsm from "../assets/images/signInBackgroundsm.png";
import signInAbstract from "../assets/images/signInAbstract.png";
import { appContext, deviceContext } from "../context/context";
import GetOTP from "../components/Auth/GetOTP";
import VerifyOTP from "../components/Auth/VerifyOTP";
import UserName from "../components/Auth/UserName";
import { Navigate } from "react-router-dom";
import { DashboardRoute } from "../constants/RouteConstants";
import analytics, { logScreenView } from "../firebase";
import { logEvent } from "firebase/analytics";
import useGetMsisdnFromHeaderEnrichment from "../hooks/headerEnrichment/useGetMsisdnFromHeaderEnrichment";

const STEP_GET_OTP = 1;
const STEP_VERIFY_OTP = 2;
const STEP_ADD_USERNAME_OTP = 3;

function Auth() {
  const { isMobileView } = useContext(deviceContext);
  const getMsisdnFromHeaderEnrichment = useGetMsisdnFromHeaderEnrichment();
  const [enrichedMsisdn, setEnrichedMsisdn] = useState(null);

  // AUTO ROUTE TO AUTH (IF NOT LOGGED IN) <-- START -->
  const { isLoggedIn, token } = useContext(appContext);
  const [authStep, setAuthStep] = useState(
    token ? STEP_ADD_USERNAME_OTP : STEP_GET_OTP
  );
  useEffect(() => {
    if (token && !isLoggedIn) {
      showAddUserNameScreen();
    }
  }, [isLoggedIn, token]);
  // eslint-disable-next-line no-unused-vars
  const showOtpVerifyScreen = () => {
    setAuthStep(STEP_VERIFY_OTP);
  };
  // eslint-disable-next-line no-unused-vars
  const showAddUserNameScreen = () => {
    setAuthStep(STEP_ADD_USERNAME_OTP);
  };
  const authSteps = [
    {
      step: STEP_GET_OTP,
      component: (
        <GetOTP
          enrichedMsisdn={enrichedMsisdn}
          goToNextScreen={showOtpVerifyScreen}
        />
      ),
    },
    {
      step: STEP_VERIFY_OTP,
      component: <VerifyOTP goToNextScreen={showAddUserNameScreen} />,
    },
    {
      step: STEP_ADD_USERNAME_OTP,
      component: <UserName />,
    },
  ];
  const selectedComponent = authSteps.filter(
    (step) => step.step === authStep
  )[0].component;

  // FIREBASE ANALYTICS <- START ->
  useEffect(() => {
    logScreenView("Auth");
    // FIREBASE ANALYTICS (DAU) <- START ->
    const logDailyActiveUser = () => {
      try {
        logEvent(analytics, "daily_active_user", {
          date: new Date().toISOString(),
        });
      } catch (error) {
        console.log(error);
      }
    };
    logDailyActiveUser();
    // FIREBASE ANALYTICS (DAU) <- END ->
    // FIREBASE ANALYTICS (WAU) <- START ->
    const getWeekNumber = (date) => {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };
    const lastWeek = localStorage.getItem("lastActiveWeek");
    const currentWeek = getWeekNumber(new Date());

    if (lastWeek !== currentWeek) {
      logEvent(analytics, "weekly_active_user", {
        week: currentWeek,
      });
      localStorage.setItem("lastActiveWeek", currentWeek);
    }
    // FIREBASE ANALYTICS (WAU) <- END ->
    // FIREBASE ANALYTICS (MAU) <- START ->
    const lastMonth = localStorage.getItem("lastActiveMonth");
    const currentMonth = new Date().getMonth() + 1;
    if (lastMonth !== currentMonth.toString()) {
      logEvent(analytics, "monthly_active_user", {
        month: currentMonth,
      });
      localStorage.setItem("lastActiveMonth", currentMonth);
    }
    // FIREBASE ANALYTICS (MAU) <- END ->
  }, []);
  // FIREBASE ANALYTICS <- END ->

  useEffect(() => {
    getMsisdnFromHeaderEnrichment((msisdn) =>
      setEnrichedMsisdn(msisdn.substring(3))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoggedIn ? (
    <Navigate to={DashboardRoute} replace />
  ) : (
    <>
      <section
        className="min-h-svh bg-[#45377E] tablet:pt-[17em] tablet:pb-[10em] mobile:pt-[9em] mobile:pb-[5em] pt-[5em] pb-[3em]"
        style={{
          backgroundImage: `url(${
            isMobileView ? signInBackgroundsm : signInBackground
          })`,
          backgroundSize: "cover",
          backgroundPositionX: "center",
        }}
      >
        {authStep !== STEP_ADD_USERNAME_OTP ? (
          <div className=" items-center tablet:flex tablet:flex-row tablet:gap-[0em] gap-[4em]  flex flex-col  tablet:justify-between tablet:px-[15em]">
            <div>
              <div>
                <img
                  className="min-w-[280px] max-w-[500px] w-[280px]  mobile:w-full h-full "
                  src={signInAbstract}
                  alt=""
                />
              </div>
            </div>
            <div className="">{selectedComponent}</div>
          </div>
        ) : (
          <div className=" flex justify-center">{selectedComponent}</div>
        )}
      </section>
    </>
  );
}

export default Auth;
