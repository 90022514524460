export const BASE_URL = process.env.REACT_APP_API_URL;

// AUTH ENDPOINTS
export const ENDPOINT_REQUEST_OTP = process.env.REACT_APP_ENDPOINT_REQUEST_OTP;
export const ENDPOINT_VERIFY_OTP = process.env.REACT_APP_ENDPOINT_VERIFY_OTP;
export const ENDPOINT_REFRESH_TOKEN =
  process.env.REACT_APP_ENDPOINT_REFRESH_TOKEN;

// USER ENDPOINTS
export const ENDPOINT_UPDATE_USERNAME =
  process.env.REACT_APP_ENDPOINT_UPDATE_USERNAME;
export const ENDPOINT_ADD_FEEDBACK =
  process.env.REACT_APP_ENDPOINT_ADD_FEEDBACK;
export const ENDPOINT_GET_POINTS = process.env.REACT_APP_ENDPOINT_GET_POINTS;
export const ENDPOINT_GET_LEADERBOARD_DAILY =
  process.env.REACT_APP_ENDPOINT_GET_LEADERBOARD_DAILY;
export const ENDPOINT_GET_LEADERBOARD_WEEKLY =
  process.env.REACT_APP_ENDPOINT_GET_LEADERBOARD_WEEKLY;

// STATIC QUIZ ENDPOINTS
export const ENDPOINT_GET_STATIC_QUIZ_CATEGORIES =
  process.env.REACT_APP_ENDPOINT_GET_STATIC_QUIZ_CATEGORIES;
export const ENDPOINT_GET_STATIC_QUIZ =
  process.env.REACT_APP_ENDPOINT_GET_STATIC_QUIZ;
export const ENDPOINT_TEST_STATIC_QUIZ_ANSWER =
  process.env.REACT_APP_ENDPOINT_TEST_STATIC_QUIZ_ANSWER;

// LIVE QUIZ ENDPOINTS
export const ENDPOINT_GET_RTM_CREDENTIALS =
  process.env.REACT_APP_ENDPOINT_GET_RTM_CREDENTIALS;
export const ENDPOINT_GET_SCHEDULED_QUIZ =
  process.env.REACT_APP_ENDPOINT_GET_SCHEDULED_QUIZ;
export const ENDPOINT_UPDATE_STATS_ON_JOIN =
  process.env.REACT_APP_ENDPOINT_UPDATE_STATS_ON_JOIN;
export const ENDPOINT_TEST_LIVE_QUIZ_ANSWER =
  process.env.REACT_APP_ENDPOINT_TEST_LIVE_QUIZ_ANSWER;
export const ENDPOINT_GET_LIVE_QUIZ_RESULT =
  process.env.REACT_APP_ENDPOINT_GET_LIVE_QUIZ_RESULT;


// HEADER ENRICHMENTS

export const BASE_URL_HEADER_ENRICHMENT_API =
process.env.REACT_APP_BASE_URL_HEADER_ENRICHMENT_API;
export const ENDPOINT_GET_MSISDN_FROM_HEADER_ENRICHMENT =
process.env.REACT_APP_ENDPOINT_GET_MSISDN_FROM_HEADER_ENRICHMENT;
