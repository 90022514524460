import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { appContext } from "../context/context";
import { LoginPageRoute } from "../constants/RouteConstants";
const AuthRoute = () => {
  const { isLoggedIn } = useContext(appContext);
  return isLoggedIn ? <Outlet /> : <Navigate to={LoginPageRoute} replace />;
};

export default AuthRoute;
