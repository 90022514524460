import axios from "axios";
import {
  BASE_URL_HEADER_ENRICHMENT_API,
  ENDPOINT_GET_MSISDN_FROM_HEADER_ENRICHMENT,
} from "../../constants/APIConstant";
import { HEADER_ENRICHMENT_HEADER_KEY_FOR_MSISDN } from "../../constants/EnvConstants";

const useGetMsisdnFromHeaderEnrichment = () => {
  const getMsisdnFromHeaderEnrichment = (callback) => {
    const url = BASE_URL_HEADER_ENRICHMENT_API + ENDPOINT_GET_MSISDN_FROM_HEADER_ENRICHMENT;
    axios
      .request({
        method: "GET",
        url: url,
        params: {
          headerName: HEADER_ENRICHMENT_HEADER_KEY_FOR_MSISDN,
        },
      })
      .then((response) => {
        const enrichedMsisdn = response?.data;
        console.log(enrichedMsisdn)
        if (enrichedMsisdn) {
          callback(enrichedMsisdn.toString());
        }
      })
      .catch(() => {});
  };
  return getMsisdnFromHeaderEnrichment;
};

export default useGetMsisdnFromHeaderEnrichment;
